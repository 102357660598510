import axios from 'axios';
import { ubtError } from '@/src/sources/common/lightUbt';

// offline 需求，把url上token参数带到接口header中
// @ts-expect-error
axios.interceptors.request.use((config) => {
	try {
		const { token } = window.offlineInfo || {};
		if (token) {
			const { headers } = config;
			return {
				...config,
				headers: {
					...headers,
					'offline-record-token': token,
				},
			};
		}
	} catch (e) {
		ubtError(e);
	}
	return config;
});
